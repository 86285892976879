import React from "react";
import { graphql, Link } from "gatsby";
import { StructuredText } from "react-datocms";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogPage = ({ data }) => {
  const { post } = data;
  return (
    <main className="max-w-md mx-auto">
      <h1 className="text-3xl mb-8">{post.title}</h1>
      {post.heroImage && (
        <div className="my-10">
          <GatsbyImage image={post.heroImage.gatsbyImageData} />
        </div>
      )}
      <StructuredText
        data={post.body}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case "DatoCmsCounterBlock":
            case "DatoCmsCta":
              return (
                <Link
                  variant={record.variant}
                  to={record.pageLink || record.url}
                  style={{ marginTop: "2rem" }}
                >
                  {record.callToAction}
                </Link>
              );
            default:
              return (
                <pre className="text-xs overflow-auto bg-gray-100 p-2 my-4">
                  {JSON.stringify(record, null, 2)}
                </pre>
              );
          }
        }}
      />
    </main>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPostDato($slug: String!) {
    post: datoCmsBlogpost(slug: { eq: $slug }) {
      id
      title
      authors {
        firstName
        lastName
        jobTitle
        avatar {
          gatsbyImageData(width: 73, height: 73)
        }
      }
      meta {
        firstPublishedAt(formatString: "DD/MM/YYYY")
      }
      heroImage {
        gatsbyImageData(width: 1120)
      }
      heroDescription
      slug
      body {
        value
        links
        blocks {
          ... on DatoCmsVideo {
            __typename
            id: originalId
            embedUrl
            wide
          }
          ... on DatoCmsMultipleImage {
            __typename
            id: originalId
            images {
              gatsbyImageData(width: 1200)
            }
          }
          ... on DatoCmsImage {
            __typename
            id: originalId
            wide
            disableSpacing
            image {
              gatsbyImageData(width: 1200)
            }
          }
        }
      }
    }
  }
`;
